// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-promotions-tsx": () => import("./../../../src/pages/promotions.tsx" /* webpackChunkName: "component---src-pages-promotions-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/About.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blogs-tsx": () => import("./../../../src/templates/Blogs.tsx" /* webpackChunkName: "component---src-templates-blogs-tsx" */),
  "component---src-templates-dump-tsx": () => import("./../../../src/templates/Dump.tsx" /* webpackChunkName: "component---src-templates-dump-tsx" */),
  "component---src-templates-projects-tsx": () => import("./../../../src/templates/Projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */)
}

